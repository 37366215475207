<template>
  <div class="product-page pd-y-20">
    <div class="flex flex-x-center">
      <el-image src="/img/product.jpg" style="width:1080px;height:257px" fit="cover" />
    </div>
    <div class="e-width pd-y-30  mg-t-20" style="border-radius: 10px 10px 10px 10px; padding-right: 260px;background:#f2f2f2">
      <el-form size="mini" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="320px" class="demo-ruleForm">
        <el-form-item label="联系人" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="tel">
          <el-input v-model="ruleForm.tel" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input v-model="ruleForm.email" placeholder="请输入Email"></el-input>
        </el-form-item>
        <el-form-item label="其他联系方式" prop="other">
          <el-input v-model="ruleForm.other" placeholder="请输入其他联系方式"></el-input>
        </el-form-item>
        <el-form-item label="留言内容" prop="content">
          <el-input type="textarea" v-model="ruleForm.content" placeholder="请输入留言内容" :rows="5"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button style="" type="primary" @click="submitForm('ruleForm')">提交留言</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        tel: "",
        email: "",
        other: "",
        content: "",
      },
      rules: {
        name: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        tel: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        content: [
          { required: true, message: "请输入留言内容", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          this.resetForm("ruleForm");
          this.$message.success("留言提交成功");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.clearForm();
      this.$refs[formName].resetFields();
    },
    clearForm() {
      for (let i in this.form) {
        this.form[i] = "";
      }
    },
  },
};
</script>
